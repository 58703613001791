import React, { useContext } from "react"
import { graphql } from "gatsby"
import styled, { ThemeContext } from "styled-components"
import BreadCrumbs from "../breadcrumbs"
import ResponsiveSpacing from "../responsive-spacing"
import Inner from "../inner"
import Spacer from "../spacer"
import Button from "../button"
import BottomColorNav from "../bottom-color-nav"
import SVG from "../svg"
import Layout from "../layout"

const SocialStyled = styled.div`
  width: 300px;
  padding: 20px 0;
  display: flex;
  justify-content: space-around;
  align-items: center;

  span {
    font-weight: bold;
    color: ${(p) => p.theme.DarkBlue};
    font-size: 14px;
    display: inline-block;
    padding: 0 5px;
  }

  .print {
    padding-left: 100px;
  }

  img {
    width: 32px;
    margin-right: 20px;
  }
`

const Social = ({ sharing }) => {
  if (typeof window === "undefined") return ""
  const url = window.location.href
  const twCopy = sharing.twitter_copy
  const fbUrl = `https://www.facebook.com/sharer.php?u=${url}`
  const twUrl = `https://twitter.com/intent/tweet?text=${twCopy} ${url}`
  const mailUrl = `mailto:?body=${url}`
  const inUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${url}`
  return (
    <SocialStyled>
      <span>SHARE</span>
      <a href={fbUrl} target="_blank" rel="noopener noreferrer">
        <img src="/icons/fb.png" />
      </a>
      <a href={inUrl} target="_blank" rel="noopener noreferrer">
        <img src="/icons/in.png" />
      </a>
      <a href={twUrl} target="_blank" rel="noopener noreferrer">
        <img src="/icons/tw.png" />
      </a>
      <a href={mailUrl} target="_blank" rel="noopener noreferrer">
        <img src="/icons/email.png" />
      </a>
    </SocialStyled>
  )
}

const ContentStyled = styled.div`
  margin: 125px auto 50px auto;
  color: ${(p) => p.theme.DarkBlue};

  h1 {
    color: ${(p) => p.theme.DarkBlue};
    font-size: 9.2em;
    margin: 0;
    font-weight: 800;
    letter-spacing: 2px;

    text-transform: uppercase;
    font-family: "interstate-compressed";
  }

  h2 {
    margin: 10px 0;
    padding: 0;
    color: ${(p) => p.theme.Blue};
    font-size: 2.4em;
    text-transform: uppercase;
  }

  .date {
    margin: 20px 0;
    padding: 0;
    color: ${(p) => p.theme.Blue};
    font-size: 1.6em;
    letter-spacing: 2px;
  }

  ul {
    margin: 0;
    padding: 0 0 0 15px;
  }

  li {
    font-size: 1.6em;
    line-height: 2em;
  }

  .body {
    margin: 0 0 100px 0;
  }
`

const Content = ({ data }) => {
  const theme = useContext(ThemeContext)
  const post = data.allWordpressWpPositions.edges[0].node
  const fields = post.acf
  const { share_comment } = fields
  const trail = [
    {
      label: "CAREERS",
      link: "/careers/",
    },
    {
      label: fields.job_title,
      link: "",
    },
  ]

  return (
    <ContentStyled>
      <ResponsiveSpacing>
        <Inner maxWidth="840px" spacing="20px 0">
          <BreadCrumbs trail={trail} />
          <h1
            className="leftWidth"
            dangerouslySetInnerHTML={{
              __html: fields.job_title,
            }}
          />
          <SVG name="SWave" color={theme.LightBlue} />
          <div className="date">{new Date().toLocaleDateString()}</div>
          <h2>OVERVIEW</h2>
          <p
            tabIndex="0"
            dangerouslySetInnerHTML={{
              __html: fields.overview,
            }}
          />
          <h2>REQUIREMENTS</h2>
          <div
            tabIndex="0"
            dangerouslySetInnerHTML={{
              __html: fields.requirements,
            }}
          />
          <div
            className="body"
            dangerouslySetInnerHTML={{
              __html: post.content,
            }}
          />
          <Button
            target="_blank"
            rel="noopener noreferrer"
            href={fields.apply_button_link_out}
          >
            {fields.apply_button_label}
          </Button>
          <Social
            sharing={{
              twitter_copy: share_comment,
            }}
          />
          <Spacer height="150px" />
        </Inner>
      </ResponsiveSpacing>

      <BottomColorNav />
    </ContentStyled>
  )
}

export default ({ data }) => {
  return (
    <Layout>
      <Content data={data} />
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    allWordpressWpPositions(filter: { id: { eq: $id } }) {
      edges {
        node {
          id
          title
          slug
          content
          acf {
            job_title
            full_time
            location
            overview
            requirements
            apply_button_label
            apply_button_link_out
            search_title
            search_description
            share_headline
            share_comment
          }
        }
      }
    }
  }
`
